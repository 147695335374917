<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form1" :model="form1" :rules="rules" label-width="90px">
      <el-row :gutter="60">
        <el-col :span="13">
          <div class="prescription">
            <div class="title">
              <h3>蛮牛健康互联网医院处方</h3>
            </div>
            <el-divider />
            <el-row :gutter="60">
              <el-col :span="12">
                <el-row>
                  <el-form-item label="姓名:">
                    {{ form.patientName }}
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="年龄:">
                    {{ form.patientAge }}岁
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="处方日期:">
                    {{ form.createTime }}
                  </el-form-item>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-form-item label="性别:">
                    {{ sexDic[form.patientSex] }}
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="科室:">
                    {{ form.deptName }}
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="处方号:">
                    {{ form.id }}
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
            <div style="margin-left: 30px">
              <el-form-item label="诊断:">
                {{ form.diagnosisName }}
              </el-form-item>
            </div>

            <el-divider />
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                margin-left: 30px;
                margin-bottom: 10px;
              "
            >
              Rp
            </div>
            <div class="drugList">
              <div v-for="item in form.medicals" :key="item.id">
                <div
                  style="margin-left: 50px; margin-bottom: 10px; display: flex"
                >
                  <div class="medical">
                    {{ item.medicalName }}
                  </div>
                  <!-- <div class="medical">
                    {{ item.specs }}
                  </div> -->
                  <div class="medical">
                    {{ item.specsPacking }}
                  </div>
                  <span>{{ item.quantity }}</span>
                </div>
                <el-form-item label="用法用量:" style="margin-left: 35px">
                  <span class="medical">{{ item.medicalUsage }}</span>
                  <span class="medical">{{ item.frequency }}</span>
                  <span class="medical"
                    >每次{{ item.consumption }}{{ item.minUseUnit }}</span
                  >
                  <span class="medical">连用{{ item.medicationDays }}天</span>
                  <span class="medical">{{ item.entrust }}</span>
                </el-form-item>
              </div>
            </div>
          </div>

          <div class="imgsty">
            <el-form-item label="医师签名:">
              <img
                v-if="form.doctorTrustSignatureUrl"
                style="width: 100px; height: 50px"
                :src="form.doctorTrustSignatureUrl"
              />
            </el-form-item>
            <template v-if="form.auditTrustSignatureUrl">
              <el-form-item label="药师签名:">
                <img
                  style="width: 100px; height: 50px"
                  :src="form.auditTrustSignatureUrl"
                />
              </el-form-item>
            </template>
          </div>
        </el-col>
        <el-col :span="11">
          <el-form-item label="审核规范:" prop="auditSpec">
            <el-select v-model="form1.auditSpec">
              <el-option
                v-for="item in specList"
                :key="item.prescriptionSpecCode"
                :value="item.prescriptionSpecCode"
                :label="item.prescriptionSpecName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="审方状态:" prop="auditStatus">
            <el-radio-group v-model="form1.auditStatus">
              <el-radio label="SUCCESS"> 可发药 </el-radio>
              <el-radio label="FAIL"> 不可发药 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审核内容:" prop="auditReason">
            <el-input
              v-model="form1.auditReason"
              placeholder="请输入审核内容"
              style="width: 80%; margin-right: 20px"
              type="textarea"
              :rows="5"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
          <template v-if="form1.auditSpec">
            <el-table
              :data="specDetailList"
              class="width-96"
              border
              :row-key="getRowKey"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true"
              />
              <el-table-column
                prop="specDetailContent"
                label="明细"
                align="center"
              />
              <el-table-column
                prop="specTypeName"
                label="规范类型"
                align="center"
              />
            </el-table>
          </template>

          <div class="btn">
            <el-button v-if="isSave" type="primary" @click="saveData">
              保存
            </el-button>
            <el-button @click="$router.back()"> 返回 </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <iframe
        :src="signPage"
        frameborder="0"
        width="100%"
        height="600px"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formatWebUrl, sexDic } from '@/utils/component/publicList';
export default {
  name: 'Details',
  data() {
    return {
      specDetailList: [], // 明细列表
      multipleSelection: [], // 选中的数据
      dialogVisible: false,
      signPage: '',
      sexDic: sexDic,
      isSave: true,
      form: {},
      form1: {
        auditReason: '',
        auditSpec: '',
      },
      rules: {
        auditReason: [
          { required: true, message: '请输入审核内容', trigger: 'blur' },
        ],
        auditSpec: [
          { required: true, message: '请选择审核规范类型', trigger: 'change' },
        ],
        auditStatus: [
          { required: true, message: '请选择审核状态', trigger: 'change' },
        ],
      },
      props: {
        label: 'name',
        children: 'children',
      },
    };
  },
  computed: {
    ...mapState('prescriptionSpec', {
      specList: (state) => state.specList,
    }),
    // isView() {
    //   return Number(this.$route.query.type);
    // }
  },
  watch: {
    'form1.auditSpec': function (value) {
      if (value) {
        this.getSpecDetailByCodeList(value);
      }
    },
    'form1.auditStatus': function (value) {
      this.form1.auditReason = value === 'SUCCESS' ? '可发药;' : '不可发药;';
    },
  },
  mounted() {
    this.getPrescriptionDetail();
    this.getSpecList();
  },
  methods: {
    ...mapActions('prescriptionSpec', ['querySpecList']),
    // 查询审核字典
    getSpecList() {
      this.querySpecList({});
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.id;
    },
    // 选中的数据
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.specDetailContent + ';');
      });
      this.form1.auditReason += String(this.multipleSelection);
    },
    // 根据审核规范编码查询规范明细
    getSpecDetailByCodeList(data) {
      this.$api
        .querySpecDetailListByCode({ prescriptionSpecCode: data })
        .then((res) => {
          this.specDetailList = res.data;
        });
    },
    // 编辑情况下保存
    saveData() {
      const param = {
        ...this.form1,
        prescriptionId: this.$route.query.id,
      };
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$api.prescriptionAudit(param).then((res) => {
            loading.close();
            if (res.code === 0) {
              this.$message.success('审核成功');
              this.isSave = false;
              if (this.form1.auditStatus === 'SUCCESS') {
                // this.pharmacistSignature(res.data);
                this.$router.back();
              }
              // this.$router.go(-1);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 处方详情
    getPrescriptionDetail() {
      const param = {
        id: this.$route.query.id,
      };
      this.$api.prescriptionDetailById(param).then((res) => {
        this.form = res.data;
        // this.querySummaryDetail(res.data);
      });
    },
    // 药师签名
    pharmacistSignature(data) {
      var isMainOms = window.__POWERED_BY_QIANKUN__ ? '/main-oms' : '';
      var address = 'https://' + window.location.hostname;
      var url = formatWebUrl({
        path: `${address}/operation-gateway/doctor-management-aggregate-service/truest/getSignQRCode`,
        query: {
          openId: data.auditTrustOpenId,
          uniqueId: data.auditUniqueId,
          redirectUrl: encodeURIComponent(
            `${address}${isMainOms}/doctor-oms/orderManage/prescriptionList`
          ),
        },
      });
      // this.signPage = url;
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.btn {
  margin: 50px 0px 0px 100px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.flex {
  display: flex;
}
.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}
.el-divider {
  margin: 10px 0px;
  background: #8c8c8c;
}
.prescription {
  padding: 0px 20px;
  border-right: 1px solid #8c8c8c;
}
.width-96 {
  width: 100%;
}
.drugList {
  margin-left: 10px;
}
.medical {
  margin-right: 15px;
}
.imgsty {
  margin-left: 50px;
  display: flex;
  align-items: center;
}
</style>
